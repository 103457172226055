import { MutationTuple, useMutation } from '@apollo/client';
import { UpdateParticipatingPlayerMatchSub } from '../..'; // Adjust the import based on your file structure

interface VariableProps {
    id: string;    // Assuming id is of type string
    sub: boolean;
}

export const useUpdateSub = (): MutationTuple<any, VariableProps> => {
    return useMutation<any, VariableProps>(UpdateParticipatingPlayerMatchSub);
};
