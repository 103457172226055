import {MutationTuple, useMutation} from "@apollo/client";
import {AddArbitre} from "../../"


interface VariableProps {
    
        id_match: string;
        Arbitre1: string;
        Arbitre2: string;
        Arbitre3: string;
        Arbitre4: string;
        
    
}

export const useAddArbitre = (): MutationTuple<any, VariableProps> => {
    return useMutation<any, VariableProps>(AddArbitre);
};