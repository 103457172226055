import { gql } from "@apollo/client";

export const AddArbitre = gql`
    mutation AddArbitre($id_match: ID!, $Arbitre1: String!, $Arbitre2: String!, $Arbitre3: String!, $Arbitre4: String!) {
        createArbitre(id_match: $id_match, Arbitre1: $Arbitre1, Arbitre2: $Arbitre2, Arbitre3: $Arbitre3, Arbitre4: $Arbitre4) {
            id
            id_match
            Arbitre1
            Arbitre2
            Arbitre4
            Arbitre3
        }
    }
`;