import {gql} from "@apollo/client";

export const AllLeagues = gql`
    query AllLeague($idClub: ID) {
        allLeagues(idClub: $idClub)
  {
    id
            name
            numberTeams
            numberGroups
            description

            startDate
            expiryDate

            participatingTeams {
                id
                group
                
                league {
                    id
                    name
                }
                
                team {
                    id
                    name
                    category
                    club {
                        id
                        name
                    }
                }
            }

            matchs {
                arbitre{
                    id
                    id_match
                    Arbitre1
                    Arbitre2
                    Arbitre4
                    Arbitre3
                
                }
                id
                date
                firstTeamGoal
                secondTeamGoal
                type

                manOfMatch
                
                firstTeam {
                    id
                    participatingPlayers{
                          id 
                          number
                          participatingPlayersMatches{
                            id
                            starter
                            sub
                            id_match{
                                id
                                }
                            }
                          player{
                            id
                            person{
                              first_name
                              second_name
                              third_name

                            }
                          }
                        }
                    team {
                        id
                        name
                    
                    }
                }
                secondTeam {
                    id
                  participatingPlayers{
                          id 
                          number
                          participatingPlayersMatches{
                            id
                            starter
                            sub
                            id_match{
                                id
                                }
                            }
                          player{
                            id
                            person{
                              first_name
                              second_name
                              third_name

                            }
                          }
                        }
                    team {
                        id
                        name
                    }
                }

                firstTeamCards {
                    id
                    type
                    player
                    date
                    team {
                        id
                        team {
                            id
                            name
                        }
                    }
                }
                
                secondTeamCards {
                    id
                    type
                    player
                    date
                    team {
                        id
                        team {
                            id
                            name
                        }
                    }
                }

                firstTeamScorersMatch {
                    id
                    time
                    participating_team {
                        id
                        team {
                            id
                            name
                        }
                    }
                    participating_player {
                        id
                        number

                        player {
                            id
                            player_center
                            person {
                                first_name
                                second_name
                                third_name
                                tribe
                            }
                        }
                    }
                }

                secondTeamScorersMatch {
                    id
                    time
                    participating_team {
                        id
                        team {
                            id
                            name
                        }
                    }
                    participating_player {
                        id
                        number

                        player {
                            id
                            player_center
                            person {
                                first_name
                                second_name
                                third_name
                                tribe
                            }
                        }
                    }
                }

                createdAt
                updatedAt
            }
            
            createdAt
            updatedAt
    
  }
    }
`;