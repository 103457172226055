import { ActionIcon, Box, Button, Grid, Group, Checkbox, Select, Tooltip } from "@mantine/core";
import { IconCheck, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import { AllLeagues, useAddParticipatingPlayersMatch, useAllPlayers, useAllParticipatingPlayers,useDeleteParticipatingPlayersMatch } from "../../graphql";

import { Notyf } from "notyf";

const { Col } = Grid;

type Props = {
    setSelectedData?: (id: string) => void;
    dataMatch?: any;
    data?: any;
} & ModalProps;



export const EditParticipatingPlayersMatch = ({ data, dataMatch, ...props }: Props) => {
    const { getInputProps, reset, onSubmit, values, insertListItem, removeListItem } = useForm({
        initialValues: {
            players: []
        }
    });


    const [deleteParticipatingPlayersMatch] = useDeleteParticipatingPlayersMatch();
    const [allTeams, setAllTeams] = useState<{ label: string, value: string }[]>([]);
    const [allPlayers, setAllPlayers] = useState<{ label: string, value: string, state: string }[]>([]);
    const [selectedPlayersToDelete, setSelectedPlayersToDelete] = useState<string[]>([]);
    const [participatingTeam, setParticipatingTeam] = useState<string | null>("");

    const [getAllPlayers] = useAllPlayers();
    const [getAllPlayersMatch] = useAllParticipatingPlayers();

    useEffect(() => {
        if (data !== null && props.opened) {
            let newAllTeams: { label: string, value: string }[] = [];

            const participatingTeams = data?.participatingTeams;

            for (let i = 0; i < participatingTeams.length; i++) {
                const item = participatingTeams[i];

                if (item?.id === dataMatch?.firstTeam?.id || item?.id === dataMatch?.secondTeam?.id) {
                    newAllTeams.push({ value: item.id, label: `${item?.team?.name} - ${item?.group}` });
                }
            }
            setAllTeams([...newAllTeams]);
        }
    }, [data, props.opened]);

    useEffect(() => {
        if (props.opened) {
            getAllPlayersMatch({
                variables: {
                    idParticipatingTeams: participatingTeam
                },
                onCompleted: ({ allParticipatingPlayers }) => {
                    let newAllPlayers: { label: string, value: string, state: string }[] = [];
                    for (let i = 0; i < allParticipatingPlayers.length; i++) {
                        const item = allParticipatingPlayers[i];
                        for (let j = 0; j < item?.participatingPlayersMatches.length; j++) {
                            const itemMatch = item?.participatingPlayersMatches[j];
                            if (itemMatch?.id_match?.id === dataMatch.id) {
                                newAllPlayers.push({
                                    value: itemMatch.id,
                                    label: `${item?.player?.person?.first_name} ${item?.player?.person?.second_name} ${item?.player?.person?.third_name} ${item?.player?.person?.tribe}`,
                                    state: itemMatch.starter
                                });
                            }
                        }
                    }
                    setAllPlayers([...newAllPlayers]);
                }
            });
        }
    }, [participatingTeam]);

    const onFormSubmit = async ({ players }: any) => {
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });
        console.log(selectedPlayersToDelete)
        try {
            // Delete selected players
            await Promise.all(selectedPlayersToDelete.map(async (playerId) => {
                const response = await deleteParticipatingPlayersMatch({ variables: { id: playerId } });
                console.log("Delete response for player ID", playerId, ":", response);
            }));
    
            // Provide feedback after deletion
            closeModal();
            notyf.success("تم حذف اللاعبين بنجاح");
        } catch (error) {
            console.error("Error deleting players: ", error);
            notyf.error("حدث خطأ أثناء حذف اللاعبين");
        }
        
    };

    const handleCheckboxChange = (playerId: string) => {
        setSelectedPlayersToDelete(prev => {
            if (prev.includes(playerId)) {
                return prev.filter(id => id !== playerId);
            } else {
                return [...prev, playerId];
            }
        });
    };

    const logSelectedPlayersToDelete = () => {
        console.log("Selected players to delete:", selectedPlayersToDelete);
    };

    const closeModal = () => {
        props.onClose();
        reset();
        setAllTeams([]);
        setAllPlayers([]);
        setSelectedPlayersToDelete([]);
        setParticipatingTeam(null);
    };

    return (
        <Modal
            size={"60%"}
            {...props} onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>إلغاء</Button>
                        <Button rightSection={<IconCheck size={15} />} type="submit" form="submit_form">تأكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({ padding: 20 })}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={40}>
                        <Col span={12} >
                            <Group wrap={"nowrap"} align={"flex-end"}>
                                <Select
                                    label={`اسم الفريق`}
                                    placeholder="اختر الفريق"
                                    withAsterisk
                                    data={allTeams}
                                    value={participatingTeam}
                                    onChange={setParticipatingTeam}
                                    style={{ width: "100%" }}
                                />
                            </Group>
                        </Col>
                        <Col span={12}>
                            <Box>
                                {allPlayers.map((player, index) => (
                                    <Group key={index} align="center"  style={{ marginBottom: '10px' }}>
                                        <Checkbox
                                            checked={selectedPlayersToDelete.includes(player.value)}
                                            onChange={() => handleCheckboxChange(player.value)}
                                        />
                                        <Box>{player.label} - {player.state ? "اساسي" : "احتياط"}</Box>
                                    </Group>
                                ))}
                            </Box>
                        </Col>
                    
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};
