import { Box, Button, Grid, Group, TextInput } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import React from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import useStore from "../../store/useStore";
import { Notyf } from "notyf";
import { useAddArbitre } from "../../graphql";

const { Col } = Grid;

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    dataMatch?: any;
} & ModalProps;

export const CreateArbitreModal = ({ data, dataMatch, ...props }: Props) => {
    const userData = useStore((state: any) => state.userData);
    const { getInputProps, reset, onSubmit } = useForm({
        initialValues: {
            Arbitre1: "",
            Arbitre2: "",
            Arbitre3: "",
            Arbitre4: ""
        }
    });
    
    const [createArbitre] = useAddArbitre();

    const onFormSubmit = (values: any) => {
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });
        console.log(values); // Print the form data

        // Call your mutation here
        createArbitre({
            variables: {
                id_match: dataMatch.id,
                Arbitre1: values.Arbitre1,
                Arbitre2: values.Arbitre2,
                Arbitre3: values.Arbitre3,
                Arbitre4: values.Arbitre4,
            },
            onCompleted: () => {
                closeModal();
                notyf.success("تم إضافة الحكام");
            },
            onError: ({ graphQLErrors }) => {
                console.log(graphQLErrors);
                notyf.error("حدث خطأ أثناء إضافة الحكام");
            }
        });
    };

    const closeModal = () => {
        props.onClose();
        reset();
    };

    return (
        <Modal
            {...props} onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>إلغاء</Button>
                        <Button rightSection={<IconCheck size={15} />} type="submit" form="submit_form">تأكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({ padding: 20 })}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={20}>
                        <Col span={6}>
                            <TextInput
                                label="حكم 1"
                                placeholder="ادخل اسم حكم 1"
                                withAsterisk
                                required
                                {...getInputProps("Arbitre1")}
                            />
                        </Col>
                        <Col span={6}>
                            <TextInput
                                label="حكم 2"
                                placeholder="ادخل اسم حكم 2"
                                withAsterisk
                                required
                                {...getInputProps("Arbitre2")}
                            />
                        </Col>
                        <Col span={6}>
                            <TextInput
                                label="حكم 3"
                                placeholder="ادخل اسم حكم 3"
                                withAsterisk
                                required
                                {...getInputProps("Arbitre3")}
                            />
                        </Col>
                        <Col span={6}>
                            <TextInput
                                label="حكم 4"
                                placeholder="ادخل اسم حكم 4"
                                withAsterisk
                                required
                                {...getInputProps("Arbitre4")}
                            />
                        </Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};
