import {ActionIcon, Box, Button, Grid, Group, NumberInput, Select, TextInput, Tooltip} from "@mantine/core";
import {IconCheck, IconPlus, IconTrash, IconX} from "@tabler/icons-react";
import React, {useEffect, useState} from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import {AllLeagues, useAddParticipatingPlayersMatch, useAllPlayers,useAllParticipatingPlayers} from "../../graphql";
import {Notyf} from "notyf";
import {DateInput} from "@mantine/dates";
import dayjs from "dayjs";

const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    dataMatch?:any;
    data?: any;
} & ModalProps;

export const AddParticipatingPlayersMatch = ({data,dataMatch, ...props}: Props) => {
    const {getInputProps, reset, onSubmit, values, insertListItem, removeListItem} = useForm({
        initialValues: {
            players: []
        }
    });
    const [createParticipatingPlayersMatch] = useAddParticipatingPlayersMatch();
    const [allTeams, setAllTeams] = useState<{ label: string, value: string }[]>([]);
    const [allPlayers, setAllPlayers] = useState<{ label: string, value: string }[]>([]);

    const [participatingTeam, setParticipatingTeam] = useState<string | null>("");

    const [getAllPlayers] = useAllPlayers();
    const [getAllPlayersMatch] = useAllParticipatingPlayers();

    useEffect(() => {
        if (data !== null && props.opened) {
            let newAllTeams: { label: string, value: string }[] = []

            const participatingTeams = data?.participatingTeams

            for (let i = 0; i < participatingTeams.length; i++) {
                const item = participatingTeams[i]
           
                if(item?.id === dataMatch?.firstTeam?.id || item?.id === dataMatch?.secondTeam?.id ){
                newAllTeams.push({value: item.id, label: `${item?.team?.name} - ${item?.group}`})}
            }
            setAllTeams([...newAllTeams])
            
        }
    }, [data, props.opened])


    useEffect(() => {
        if (props.opened) {
         
            getAllPlayersMatch({
                variables: {
                    idParticipatingTeams: participatingTeam
                },
                onCompleted: ({allParticipatingPlayers}) => {

                    let newAllPlayers: { label: string, value: string }[] = []
                    for (let i = 0; i < allParticipatingPlayers.length; i++) {
                        const item = allParticipatingPlayers[i]
                       
                        newAllPlayers.push({
                            value: item.id,
                            label: `${item?.player?.person?.first_name} ${item?.player?.person?.second_name} ${item?.player?.person?.third_name} ${item?.player?.person?.tribe}`
                        })
                    }
                    setAllPlayers([...newAllPlayers])
                }
            })

         
            
        
           
        }
    }, [participatingTeam])

    const onFormSubmit = ({players}: any) => {
       
        
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

        let newPlayers = []
        for (let i = 0; i < players.length; i++) {
            const player = players[i]
        
            newPlayers.push({
                starter: player.type === "true",
                id_match: dataMatch.id,
                id_participating_player: player.id_player,
                
            })
        }
    

        createParticipatingPlayersMatch({
            variables: {
                contentParticipatingPlayerMatch: newPlayers
            },
            refetchQueries: [AllLeagues],
            onCompleted: () => {
                closeModal();
                notyf.success("تم اضافة القائمة ")
            },
            onError: ({graphQLErrors}) => {
                console.log(false)
            }
        })
    };

    const addItem = () => {
        insertListItem('players', {
            id_player: "",
            id_participating_team: participatingTeam,
            number: ""
        })
    }

    const removeItem = (index: number) => {
        removeListItem('players', index)
    }

    const closeModal = () => {
        

        props.onClose();
        reset();
        setAllTeams([])
        setAllPlayers([])
        setParticipatingTeam(null)
    };

    return (
        <Modal
        size={"60%"}
            {...props} onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>إلغاء</Button>
                        <Button rightSection={<IconCheck size={15} />} type="submit" form="submit_form">تأكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={40}>
                        <Col span={12} >
                            <Group wrap={"nowrap"} align={"flex-end"}>
                                <Select
                                    label={`اسم الفريق`}
                                    placeholder="اختر الفريق"
                                    withAsterisk
                                    data={allTeams}
                                    value={participatingTeam}
                                    onChange={setParticipatingTeam}

                                    style={{width: "100%"}}
                                />

                                <Tooltip label={"اضافة لاعب"} >
                                    <ActionIcon size={26} variant={"filled"} color={"teal"} onClick={addItem}>
                                        <IconPlus size="1.125rem" />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Col>

                        {values.players.map((item, index) => (
                            <Col span={12} key={index}>
                                <Group wrap={"nowrap"} justify={"space-between"} align={"flex-end"}>
                                    <Grid gutter={20} style={{width: "100%"}} >
                                        <Col span={6} >
                                            <Select
                                                label={`اسم اللاعب ${index+1}`}
                                                placeholder="اختر اللاعب"
                                                withAsterisk
                                                data={allPlayers}
                                                {...getInputProps(`players.${index}.id_player`)}
                                                style={{width: "100%"}}
                                            />
                                        </Col>
                                       
                                        <Col span={6} >
                                            <Select
                                                placeholder="الحالة"
                                                label="اختار الحالة"
                                                withAsterisk
                                                data={[
                                                    {label: "اساسي", value: "true"},
                                                    {label: "احتياط", value: "false"},
                                                ]}
                                                {...getInputProps(`players.${index}.type`)}
                                            />
                                        </Col>
                                    </Grid>

                                    <Tooltip label={"حذف لاعب"} >
                                        <ActionIcon size={36} variant={"filled"} color={"red"} onClick={() => removeItem(index)}>
                                            <IconTrash size="1.125rem" />
                                        </ActionIcon>
                                    </Tooltip>
                                </Group>
                            </Col>
                        ))}
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};