import { Box, Grid, Group, useMantineTheme, Stack, Text, ActionIcon, Button, Divider } from "@mantine/core";
import { IconX, IconArrowBigLeft, IconArrowBigRight } from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { useEffect } from "react";

const { Col } = Grid;

type Props = {
    opened: boolean;
    dataMatch?: any;
    onClose: () => void;
} & ModalProps;

export const ShowPlayerListModal = ({ dataMatch, onClose, opened }: Props) => {
    const theme = useMantineTheme();

    const closeModal = () => {
        onClose();
    };

    useEffect(() => {
        console.log("dataMatch");
        console.log(dataMatch);
    }, [dataMatch, opened]);

    const renderPlayerStarter = (player: any, match: any, index: number) => (
        <Group key={index} align="center">
            <Text size={"md"} color={theme.colors.gray[7]}>
                {player?.player?.person?.first_name} {player?.player?.person?.second_name} {player?.player?.person?.third_name}
            </Text>
            {match?.starter === false && match?.sub === true ? (
                <IconArrowBigLeft color="#FF0000" fill="#FF0000" size={14} />
            ) : null}
        </Group>
    );

    const renderPlayerSub = (player: any, match: any, index: number) => (
        <Group key={index} align="center">
            <Text size={"md"} color={theme.colors.gray[7]}>
                {player?.player?.person?.first_name} {player?.player?.person?.second_name} {player?.player?.person?.third_name} 
            </Text>
            {match?.starter === true && match?.sub === true ? (
                <IconArrowBigRight color="#41b883" fill="#41b883" size={14} />
            ) : null}
        </Group>
    );
    return (
        <Modal
            opened={opened}
            onClose={closeModal}
            size={"80%"}
            title="قائمة اللاعبين"
            styles={{
                body: {
                    backgroundColor: theme.colors.gray[1],
                },
                header: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: theme.spacing.sm,
                },
                title: {
                    fontSize: theme.fontSizes.lg,
                    fontWeight: 700,
                },
                close: {
                    marginLeft: theme.spacing.md,
                },
            }}
        >
            <Box style={{ padding: 20 }}>
                <Grid gutter={20}>
                    <Col span={6}>
                        <Box bg={theme.white} style={{ padding: 20 }}>
                            <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                {dataMatch?.firstTeam?.team?.name}
                            </Text>
                            <Divider my="sm" />
                            <Stack gap="xs">
                                <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                    الاساسين
                                </Text>
                                {dataMatch?.firstTeam?.participatingPlayers?.map((player: any, index: number) =>
                                    player?.participatingPlayersMatches?.map((match: any) => {
                                        if ((match?.starter && !match?.sub) || (!match?.starter && match?.sub)) {
                                            return renderPlayerStarter(player, match, index);
                                        }
                                        return null;
                                    })
                                )}
                                <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                    الاحتياط
                                </Text>
                                {dataMatch?.firstTeam?.participatingPlayers?.map((player: any, index: number) =>
                                    player?.participatingPlayersMatches?.map((match: any) => {
                                        if ((match?.starter && match?.sub) || (!match?.starter && !match?.sub)) {
                                            return renderPlayerSub(player, match, index);
                                        }
                                        return null;
                                    })
                                )}
                            </Stack>
                        </Box>
                    </Col>
                    <Col span={6}>
                        <Box bg={theme.white} style={{ padding: 20 }}>
                            <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                {dataMatch?.secondTeam?.team?.name}
                            </Text>
                            <Divider my="sm" />
                            <Stack gap="xs">
                                <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                    الاساسين
                                </Text>
                                {dataMatch?.secondTeam?.participatingPlayers?.map((player: any, index: number) =>
                                    player?.participatingPlayersMatches?.map((match: any) => {
                                        if ((match?.starter && !match?.sub) || (!match?.starter && match?.sub)) {
                                            return renderPlayerStarter(player, match, index);
                                        }
                                        return null;
                                    })
                                )}
                                <Text size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>
                                    الاحتياط
                                </Text>
                                {dataMatch?.secondTeam?.participatingPlayers?.map((player: any, index: number) =>
                                    player?.participatingPlayersMatches?.map((match: any) => {
                                        if ((match?.starter && match?.sub) || (!match?.starter && !match?.sub)) {
                                            return renderPlayerSub(player, match, index);
                                        }
                                        return null;
                                    })
                                )}
                            </Stack>
                        </Box>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};
