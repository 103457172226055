import {Box, Button, Grid, Group, TextInput, Textarea, NumberInput, Select} from "@mantine/core";
import {IconCalendar, IconCheck, IconChevronDown, IconX} from "@tabler/icons-react";
import React, {useEffect, useRef, useState} from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import {AllLeagues, useAddLeague, useAddMatch, useAddMatchCard, useUpdateMatch} from "../../graphql";
import useStore from "../../store/useStore";
import {Notyf} from "notyf";
import {DateInput, DateTimePicker, TimeInput} from "@mantine/dates";
import dayjs from "dayjs";

const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
} & ModalProps;

export const UpdateManOfMatch = ({data, ...props}: Props) => {
    const userData = useStore((state: any) => state.userData);
    const {getInputProps, reset, onSubmit, setValues} = useForm({
        initialValues: {manOfMatch: ""}
    });

    const [updateMatch] = useUpdateMatch();

    useEffect(() => {
        if (data !== null && props.opened) {
            setValues({
                manOfMatch: data.manOfMatch
            })
        }
    }, [data, props.opened])

    const onFormSubmit = ({manOfMatch}: any) => {
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

        updateMatch({
            variables: {
                id: data.id,
                content: {
                    manOfMatch
                }
            },
            refetchQueries: [AllLeagues],
            onCompleted: () => {
                closeModal();
                notyf.success("تم اضافة رجل المباراة")
            },
            onError: ({graphQLErrors}) => {
                console.log(false)
            }
        })
    };

    const closeModal = () => {
        props.onClose();
        reset();
    };

    return (
        <Modal
            {...props} onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>إلغاء</Button>
                        <Button rightSection={<IconCheck size={15} />} type="submit" form="submit_form">تأكيد</Button>
                    </Group>
                </Box>
            }
        >

            <Box style={({ colors }) => ({padding: 20})}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={20}>
                        <Col span={12} >
                            <TextInput
                                placeholder="اللاعب "
                                label="اللاعب"
                                withAsterisk
                                {...getInputProps("manOfMatch")}
                            />
                        </Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};